import React from 'react';
import { Link } from 'react-router-dom';

function Registeration() {
    return (
        <section className="bg-no-repeat bg-cover" style={{ backgroundImage: "url(https://images4.alphacoders.com/133/1336451.jpg)" }}>
            <div className="container mx-auto h-screen flex justify-center items-center">
                <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="px-6 py-4">
                        <div className="flex justify-center">
                            <img className="w-[150px] pb-3" src="http://localhost:3000/static/media/Logo.af4bcbe91e0ddba8f1a1.png"alt="" />
                        </div>
                        <form className="flex flex-wrap">
                            <div className="w-full md:w-1/2 px-2">
                                <input
                                    className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] mb-3"
                                    type="text" placeholder="First Name" aria-label="First Name" />
                            </div>
                            <div className="w-full md:w-1/2 px-2">
                                <input
                                    className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] mb-3"
                                    type="text" placeholder="Last Name" aria-label="Last Name" />
                            </div>
                            <div className="w-full md:w-1/2 px-2">
                                <input
                                    className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] mb-3"
                                    type="text" placeholder="Phone" aria-label="Phone" />
                            </div>
                            <div className="w-full md:w-1/2 px-2">
                                <input
                                    className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] mb-3"
                                    type="email" placeholder="Email Address" aria-label="Email Address" />
                            </div>
                            <div className="w-full px-2">
                                <input
                                    className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] mb-3"
                                    type="password" placeholder="Password" aria-label="Password" />
                            </div>
                            <div className="w-full px-2">
                                <input
                                    className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] mb-3"
                                    type="password" placeholder="Confirm Password" aria-label="Confirm Password" />
                            </div>
                            <div className="w-full px-2">
                                <button className="w-full px-6 py-2 text-sm font-medium rounded-[4px] bg-[#d42036] text-white">
                                    Register
                                </button>
                            </div>
                        </form>

                    </div>

                    <div className="py-4 text-center bg-gray-50 dark:bg-gray-700">
                        <span className="text-sm text-gray-600 dark:text-gray-200">Already have an an account?</span>

                        <Link to="/login" className="mx-2 text-sm font-bold text-[#d42036]">Login</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Registeration;
