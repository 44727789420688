import React from 'react'
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

function Thanks() {
    const navigate = useNavigate();
    setTimeout(() => {
        navigate('/');
    }, 2000);
    return (
        <div>
            <section className=' text-white'>
                <div class="bg-white py-[48px] px-[22px] text-center">
                    <div class="wrapper-2 flex-grow flex flex-col justify-center items-center">
                        <IoCheckmarkCircleSharp className='text-black text-[68px] text-red-700' />
                        <h1 class="text-4xl text-blue-500 mb-5">Thank you !</h1>
                        <p class="font-sans text-lg text-gray-700 mb-1">Thanks for subscribing to our newsletter.</p>
                        <p class="font-sans text-lg text-gray-700 mb-4">You should receive a confirmation email soon.</p>
                        <button class="bg-[#c03] hover:bg-[#000]  hover:text-[#c03] transition text-white px-10 py-3 rounded-full uppercase shadow-lg">go home</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Thanks
