import React from 'react'
import { BiSolidOffer } from "react-icons/bi";

function Buy() {
    return (
        <div className='buy-now py-[50px]'>
        <div className='container'>
            <div class="grid grid-cols-2 bg-[#212121] text-black flex items-center gap-2 rounded-md relative mx-6">
                <div class="responsivecss lg:text-[28px] sm:text-[26px] font-bold text-white p-8 leading-36"><h2>Get extra 10% OFF</h2>
                <h3 className='lg:text-[27px] sm:text-[25px] font-normal'>on Credit Card</h3>
                </div>
                <div class="responsivecss-btn shadow-animation text-end bg-white rounded-tl-full p-14"> <button class="hover:bg-[#212121] hover:text-white hover:border-[#d42036] bg-[#d42036] rounded-md text-white px-10 py-2 text-lg font-semibold duration-300">Buy Now</button></div>
                <BiSolidOffer className='animate-spin-slow p-[4px] text-white text-[58px] bg-[#212121] rounded-full absolute top-[-28px] left-[40px]'/>
            </div>
            </div>
        </div>
    )
}

export default Buy
