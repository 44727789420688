import React from "react";

function Homepage() {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex items-center justify-center">
      <div className="bg-black bg-opacity-50 p-8 rounded-lg text-center max-w-lg mx-auto">
        <h1 className="text-4xl font-bold mb-4">Stream Anywhere, Anytime</h1>
        <p className="text-lg mb-6">
          Download our mobile app to enjoy live channels and premium content.
        </p>
        {/* <div className="flex justify-center space-x-4">
          <button className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition">
            Download for iOS
          </button>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition">
            Download for Android
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default Homepage;
