import React from 'react';

const YouTubeEmbed = ({ videoId }) => {
  const url = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;  
  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', borderRadius: '6px' }}>
    <iframe
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
      title="YouTube video player"
    ></iframe>
  </div>
  );
}

const Watching = () => {
  return (
    <section className='video-slider py-[50px] px-5'>
      <div className="container mx-auto">
        <div className="pb-6">
          <h2 className='text-[30px] font-semibold text-[#d42036]'>Continue Watching</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex items-center">
          <div className="text-left">
            <h4 className='text-[36px] text-white font-[600]'>BRAHMĀSTRA OFFICIAL TRAILER.</h4>
            <p className='text-[17px] py-3 text-white'>
            Presenting the official Trailer of Brahmāstra. Get ready to experience a new world of Ancient Indian Astras and the magic of this extraordinary journey.
            Brahmāstra Part One: Shiva. In cinemas. September 9th
            </p>
            <button className="bg-[#d42036] hover:bg-[#fff] hover:text-black hover:border-[#d42036] rounded-md text-white px-12 py-2 text-lg font-semibold mt-4 duration-300">
              Join Now
            </button>
          </div>
          <div className="">
            <YouTubeEmbed videoId="BUjXzrgntcY" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Watching;
