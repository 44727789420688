import React from 'react';

function Privacy() {
    return (
        <div>
            <section className=' text-white bg-[#333]'>
                <div className='py-[50px] grid grid-cols-1 px-5'>
                    <div className='container'>
                        <h1 className="text-xl lg:text-[28px] font-semibold text-vnet-blue mb-2">Privacy policy</h1>
                        <p className='pb-3'>This Privacy Statement explains how we collect, use, and disclose your personal information when you use the "ashke service” to access “ashke content” as those terms are defined in the ashke Terms of Use (ashke.com/terms). It also explains what privacy rights you have and how to exercise them. Certain functionalities or apps that are part of the ashke service may also provide you with contextual privacy information or choices, in addition to the information and choices described in this Privacy Statement. Please note that this Privacy Statement may be easier to navigate when viewed on your web browser. </p>
                        <h4 className='text-[22px] pb-2'>Contacting Us </h4>
                        <p className='pb-3'>For questions about this Privacy Statement, our use of your personal information, or how to exercise your privacy rights, please contact our Data Protection Officer/Privacy Office at privacy@ashke.com. For general questions about the ashke service, your account, or how to contact customer service, please visit help.ashke.com. </p>
                        <h3 className='text-[22px] pb-2'>Section A: Our Collection, Use, and Disclosure of Personal Information</h3>
                        <p className='pb-3'>We collect the following categories of personal information about you</p>
                        <p><strong className='text-[20px] font-bold text-[#d42036] block mt-3'>🗸 Personal details</strong>When you create your ashke account, we collect your contact information (such as your email address) and authentication information for your login (such as a password). Depending on how you subsequently set up your account and method of payment, and which features you use, we also collect one or more of the following: first and last name, phone number, postal address, and other identifiers you provide to us. If you subscribe to an ad supported subscription plan, we also collect gender and date of birth.</p>
                        <p><strong className='text-[20px] font-bold text-[#d42036] block mt-3'>🗸 Payment details </strong>We collect your payment details, and other information to process your payments, including your payment history, billing address, and gift cards you have redeemed.</p>
                        <p><strong className='text-[20px] font-bold text-[#d42036] block mt-3'>🗸 ashke account/profile information</strong>We collect information that is associated with your ashke account and/or ashke profiles on your account (such as profile name and icon, ashke game handle, ratings and feedback you provide for ashke content), “My List” (watch list of titles), “continue watching” information, account/profile settings, and choices in connection with your use of the ashke service.</p>
                        <p><strong className='text-[20px] font-bold text-[#d42036] block mt-3'>🗸 Usage information</strong>We collect information about your interaction with the ashke service (including playback events, such as play, pause, etc.), choices made when engaging with interactive titles, your ashke game activity (such as gameplay, game use and interaction information, and progress or saved game information), ashke viewing and gaming history, search queries on the ashke service, and other information about your use and interaction with the ashke service (such as app clicks, text input, time and duration of access, and camera/photo access for QR-code and similar functionality).</p>
                        <p><strong className='text-[20px] font-bold text-[#d42036] block mt-3'>🗸 Advertising information</strong>If you subscribe to an ad supported subscription plan, we collect information about the ads on ashke (“Advertisements,” as defined in the ashke Terms of Use) that you view or interact with, device information (such as resettable device identifiers), IP addresses, and information provided by Advertising Companies (such as information about your likely interests they have collected or inferred from your visits to other websites and apps). We use this information to display Advertisements to you in the ashke service (including behavioral advertisements in accordance with your preferences).</p>
                        <p><strong className='text-[20px] font-bold text-[#d42036] block mt-3'>🗸 Device and network information</strong>We collect information about your computer or other ashke capable devices you might use to access our service (such as smart TVs, mobile devices, set top boxes, gaming systems, and other streaming media devices), your network, and network devices.</p>
                    </div>
                </div>
            </section>
            <p></p>
        </div>
    )
}

export default Privacy
