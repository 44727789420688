import React from 'react';
import { Link } from 'react-router-dom';
function Login() {
  return (
    <section className="bg-no-repeat bg-cover" style={{ backgroundImage: "url(https://images4.alphacoders.com/133/1336451.jpg)" }}>
      <div className="container mx-auto h-screen flex justify-center items-center">
        <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="px-6 py-4">
            <div className="flex justify-center">
              <img className="w-[150px]" src="http://localhost:3000/static/media/Logo.af4bcbe91e0ddba8f1a1.png" alt="" />
            </div>

            <h3 className="mt-3 text-xl font-medium text-center text-black">Welcome Back</h3>

            <form>
              <div className="w-full mt-4">
                <label className='text-black text-base mb-[4px] inline-block'>Enter Email*</label>
                <input className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]" type="email" placeholder="Email Address" aria-label="Email Address" />
              </div>

              <div className="w-full mt-4">
              <label className='text-black text-base  mb-[4px] inline-block'>Enter Password*</label>
                <input className="w-full h-[42px] border border-[#d42036] px-[15px] text-[13px] rounded-[4px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]" type="password" placeholder="Password" aria-label="Password" />
              </div>

              <div className="flex items-center justify-between mt-4">
                <Link to="/" className="text-sm text-gray-600 dark:text-gray-200 hover:text-gray-500">Forget Password?</Link>

                <button className="px-6 py-2 text-sm font-medium rounded-[4px] bg-[#d42036] text-white">
                  Login
                </button>
              </div>
            </form>
          </div>

          <div className="py-4 text-center bg-gray-50 dark:bg-gray-700">
            <span className="text-sm text-gray-600 dark:text-gray-200">Don't Have An Account?</span>
            <Link to="/register" className="mx-2 text-sm font-bold text-[#d42036]">Register</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login;
