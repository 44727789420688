import React from "react";
import Slider from "react-slick";
import { ImageIcons } from "../imageComponent";
function Banner() {
  const settings = {
    dots: false,
    prevArrow: false,
    nextArrow: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    arrows:false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [            
      {
        breakpoint: 768,      
        settings: {
          slidesToShow: 3,    
            
          centerMode: false, 
          slidesToScroll: 1  
        }
      },
      {
        breakpoint: 600,     
        settings: {
          slidesToShow: 1,    
          slidesToScroll: 1  
        }
      },
      {
        breakpoint: 480,     
        settings: {
          slidesToShow: 1,    
          slidesToScroll: 32  
        }
      }
    ]
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img className="w-full cursor-pointer" src={ImageIcons.Slider1} alt="slider1" />
        </div>
        <div>
        <img className="w-full cursor-pointer" src={ImageIcons.Slider2} alt="slider2" />
        </div>
        <div>
        <img className="w-full cursor-pointer" src={ImageIcons.Slider3} alt="slider3" />
        </div>
        <div>
        <img className="w-full cursor-pointer" src={ImageIcons.Slider4} alt="slider4" />
        </div>
        <div>
        <img className="w-full cursor-pointer" src={ImageIcons.Slider2} alt="slider5" />
        </div>
      </Slider>
    </div>
  );
}

export default Banner;
