import React, { useState } from 'react';

const AccordionItem = ({ title, content, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="bg-[#000] my-2">
      <h2 onClick={toggleAccordion} className="flex justify-between items-center font-semibold p-3 cursor-pointer text-black text-[19px] lg:text-xl">
        <span className='text-white block'>{title}</span>
        <svg
          className={`fill-current text-[#c03] h-6 w-6 transform ${isOpen ? 'rotate-180' : ''} transition-transform duration-500`}
          viewBox="0 0 20 20"
        >
          <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
        </svg>
      </h2>
      <div className={`border-l-2 border-red-700 overflow-hidden duration-500 transition-all ${isOpen ? `max-h-full` : `max-h-0`}`}>
        <p className="p-3 text-white-600">{content}</p>
      </div>
    </li>
  );
};

const Faq = () => {
  const accordionData = [
    { title: 'What is Ashke?', content: 'Ashke is a streaming service that offers a wide variety of award-winning TV shows, movies, anime, documentaries and more – on thousands of internet-connected devices.' },
    { title: 'How much does Ashke cost?', content: 'Watch Ashke on your smartphone, tablet, Smart TV, laptop, or streaming device, all for one fixed monthly fee. Plans range from ₹149 to ₹649 a month. No extra costs, no contracts.' },
    { title: 'Where can I watch?', content: 'Watch anywhere, anytime. Sign in with your Ashke account to watch instantly on the web at Ashke.com from your personal computer or on any internet-connected device that offers the Ashke app, including smart TVs, smartphones, tablets, streaming media players and game consoles.' },
    { title: 'How do I cancel?', content: 'Ashke is flexible. There are no annoying contracts and no commitments. You can easily cancel your account online in two clicks. There are no cancellation fees – start or stop your account anytime.' },
    { title: 'What can I watch on Ashke?', content: 'Ashke has an extensive library of feature films, documentaries, TV shows, anime, award-winning Ashke originals, and more. Watch as much as you want, anytime you want.' },
    { title: 'Is Ashke good for kids?', content: 'The Ashke Kids experience is included in your membership to give parents control while kids enjoy family-friendly TV shows and films in their own space. Kids profiles come with PIN-protected parental controls that let you restrict the maturity rating of content kids can watch and block specific titles you don’t want kids to see.' },
  ];

  return (
    <section className=' text-white bg-[#333]'>
      <div className='py-[50px] grid grid-cols-1 gap-4 px-4 lg:px-12'>
        <div className="flex justify-center items-start my-2">
          <div className="w-full lg:w-[80%] mx-auto my-1">
            <h1 className="text-[30px] lg:text-[28px] font-semibold text-vnet-blue mb-2 text-center">Frequently Asked Questions</h1>
            <ul className="flex flex-col">
              {accordionData.map((item, index) => (
                <AccordionItem key={index} title={item.title} content={item.content} index={index} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
