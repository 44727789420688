import React, {useState, useEffect} from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { validateEmail } from '../utils/formValidation';
import { PostRequest } from '../utils/request';
import Toastify from '../components/shared/toastify';
import { MdLocationPin } from "react-icons/md";
function Footer() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    message: "",
    success: false
  });
  const handlesubmit = (e)=>{
     e.preventDefault();
     setError({ message: "" });
     if (!email.length > 0) {
       setError({ message: "Fields must not be empty!", success: false })
     } else if (!validateEmail(email)) {
       setError({ message: "Email is invalid!", success: false });
     } else {
       PostRequest(`${process.env.REACT_APP_API_URL}/newsletter`, { email }).then((response) => {
         setEmail("");
         setError({ message: response.data, success: true });
         console.log(response);
       }).catch((error) => {
         console.log(error)
         setError({ message: error.data, success: true });
       })
     }
  }
  useEffect(() => {
    if (error.success) {
      const timer = setTimeout(() => {
        setError({ success: false });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error.success]);
  return (
    <footer className=' grid grid-cols-1 lg:py-10 sm:py-4 md:py-4 px-5 bg-[#212121]'>
      <div className='container'>
        <div class="">
          <div class="">
            <div class="text-teal-600">
            </div>
            <div class="mt-8 grid gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16 w-full">
              <div class="col-span-2">
                <div>
                  {/* <h2 class="text-[28px] font-normal text-[white]">Join 2,000+ subscribers</h2> */}

                  {/* <p class="mt-4 text-[17px] text-white">
                    Stay in the loop with everything you need to know.
                  </p> */}
                </div>
              </div>

              {/* <div class="col-span-2 lg:col-span-3 lg:flex lg:items-end justify-end">
                <form onSubmit={handlesubmit}>
                  <div class="relative rounded-md overflow-hidden">
                    <input
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e)=>{
                        setEmail(e.target.value)
                        setError(() => ({message: "",}));
                      }}
                      placeholder="Enter Your Email"
                      class="w-full h-14 px-3 focus:outline-none pr-48 border-gray-300 rounded-md focus:border-gray-400 transition duration-300"
                    />
                      {error.success && <Toastify message={error.message} />}
                    {!error.success && (
                    <div className="pt-1" style={{ color: "#fd5901" }}>
                      {error.message}
                    </div>

                  )}
                    <button
                      type="submit"
                      class="absolute right-0 top-0 h-14 text-white bg-[#d42036] px-6 py-3 rounded-br-[0.375rem] duration-300 text-lg font-semibold"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>

              </div> */}

              {/* <div class="col-span-2 sm:col-span-1">
                <p class="font-bold text-[#d42036] text-[20px]">Popular TV Shows</p>

                <ul class="mt-3 space-y-3 text-sm">
                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]">Korean Drama </a>
                  </li>

                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]">Indira </a>
                  </li>
                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]">Shakalaka Bhum</a>
                  </li>
                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]">Pakistani Serials</a>
                  </li>
                </ul>
              </div> */}

              {/* <div class="col-span-2 sm:col-span-1">
                <p class="font-bold text-[#d42036] text-[20px]">Company</p>

                <ul class="mt-3 space-y-3 text-sm">
                <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]"> About </a>
                  </li>

                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]"> Meet the Team </a>
                  </li>

                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]"> Accounts Review </a>
                  </li>
                </ul>
              </div>

              <div class="col-span-2 sm:col-span-1">
                <p class="font-bold text-[#d42036] text-[20px]">Helpful Links</p>

                <ul class="mt-3 space-y-3 text-sm">
                <li className='after_li relative pl-[15px]'>
                    <a href="/contact-us" class="text-white transition text-[16px]"> Contact Us </a>
                  </li>

                  <li className='after_li relative pl-[15px]'>
                    <a href="/faqs" class="text-white transition text-[16px]"> FAQs </a>
                  </li>

                  <li className='after_li relative pl-[15px]'>
                    <a href="/privacy-policy" class="text-white transition text-[16px]">Privacy Policy </a>
                  </li>
                </ul>
              </div> */}

              {/* <div class="col-span-2 sm:col-span-1">
                <p class="font-bold text-[#d42036] text-[20px]">Legal</p>

                <ul class="mt-3 space-y-3 text-sm">
                <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]"> Accessibility </a>
                  </li>

                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]"> Returns Policy </a>
                  </li>

                  <li className='after_li relative pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]"> Refund Policy </a>
                  </li>

                  <li className='after_li relative pl-[15px] pl-[15px]'>
                    <a href="/" class="text-white transition text-[16px]"> Hiring Statistics </a>
                  </li>
                </ul>
              </div> */}

              {/* <div class="col-span-2 sm:col-span-1">
                <p class="font-bold text-[#d42036] text-[20px]">Contact Us</p>

                <ul class="mt-3 space-y-3 text-sm">

                  <li>
                    <a href="/" class="text-white transition text-[16px] flex">
                    <div>
                    <MdEmail className='text-[26px] text-[#d42036] mr-2' />
                    </div>
                    support@ashke.in</a>
                  </li>
                  
                  <li>
                    <a href="/" class="text-white transition text-[16px] flex ">
                    <div>
                    <MdLocationPin className='text-[32px] text-[#d42036] mr-2' />
                    </div>
                    B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</a>
                  </li>
                </ul>
              </div> */}
              {/* <ul className="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end">
                <li>
                  <a
                    href="/"
                  >
                    <FaFacebookF className='w-9 h-9 text-white p-[5px]  bg-[#d42036] hover:text-[#000] hover:bg-[#fff] transition' />
                  </a>
                </li>

                <li>
                  <a
                    href="/"
                  >
                    <GrInstagram className='w-9 h-9 text-white p-[5px]  bg-[#d42036] hover:text-[#000] hover:bg-[#fff] transition' />
                  </a>
                </li>

                <li>
                  <a
                    href="/"
                  >
                    <BsTwitterX className='w-9 h-9 text-white p-[5px]  bg-[#d42036] hover:text-[#000] hover:bg-[#fff] transition ' />
                  </a>
                </li>

                <li>
                  <a
                    href="/"
                  >
                    <FaLinkedinIn className='w-9 h-9 text-white p-[5px]  bg-[#d42036] hover:text-[#000] hover:bg-[#fff] transition' />
                  </a>
                </li>

                <li>
                  <a
                    href="/"
                  >
                    <FaPinterestP className='w-9 h-9 text-white p-[5px]  bg-[#d42036] hover:text-[#000] hover:bg-[#fff] transition' />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>

          <div class="mt-8 border-t border-gray-100 py-8">
            <div class="sm:flex sm:justify-between">
              <p class="text-[16px] text-white">&copy; 2025. Ashke. All rights reserved.</p>

              <ul class="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end">
                {/* <li>
                  <a href="/terms-and-conditions" class="text-white transition text-[16px]"> Terms & Conditions </a>
                </li> */}

                <li className='py-1 pr-16'>
                  <a href="/privacy-policy" class="text-white transition text-[16px]"> Privacy Policy </a>
                </li>

                {/* <li>
                  <a href="/" class="text-white transition text-[16px]"> Cookies </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer;
