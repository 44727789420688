import React from 'react'
import { IoSearchOutline } from "react-icons/io5";

function Search() {
    return (
        <div>
            <div class="flex justify-center bg-[#505050] py-10">
                <div className='flex items-center'>
                <img className='w-12 h-12 mr-4 cursor-pointer' src='https://altt.co.in/assets/svgs/mic.svg' alt='voice'></img>
                    <div class="relative mr-3 intro-x sm:mr-6">
                            <input class="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800 text-lg placeholder:text-white-400/90 focus:ring-3 focus:ring-primary focus:ring-opacity-20 border-opacity-40 dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500 border border-white lg:w-[900px] rounded-lg bg-[#434343] text-white pr-8 duration-300 ease-in-out focus:lg:w-[950px] py-3 px-12 outline-none shadow-none" type="text" placeholder="Type here to search" />
                            <IoSearchOutline  className='lucide lucide-search-icon stroke-1.5 absolute inset-y-0 left-0 w-9 h-9 my-auto mr-3 text-[#fff] bg-[#434343]] pl-2 cursor-pointer' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search
