import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layout';
import Home from './pages/home';
import Login from './pages/login';
import Registeration from './pages/registeration';
import ContactUs from './pages/contactUs';
import Search from './pages/search';
import TermsofUse from './pages/termsofUse';
import Privacy from './pages/privacy';
import Faq from './pages/faq';
import Thanks from './pages/thanks';
function App() {
  return (
    <>
      <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path='/login' element={<Login/>} />
          <Route path='/register' element={<Registeration/>} />
          <Route path='/search' element={<Search/>} />
          <Route path='/contact-us' element={<ContactUs/>} />
          <Route path='/faqs' element={<Faq/>} />
          <Route path='/terms-and-conditions' element={<TermsofUse/>} />
          <Route path='/privacy-policy' element={<Privacy/>} />
          <Route path='/thanks' element={<Thanks/>} />
        </Routes>
      </Layout>
    </BrowserRouter>
    </>
  );
}

export default App;
