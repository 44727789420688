import React from 'react';

function TermsofUse() {
    return (
        <div>
            <section className=' text-white bg-[#333]'>
                <div className='py-[50px] grid grid-cols-1 px-5'>
                    <div className='container'>
                        <h1 className="text-xl lg:text-[28px] font-semibold text-vnet-blue mb-2">Terms of Use</h1>
                        <p className='pb-3'>Ashke provides a personalized subscription service that allows our members to access entertainment content (“ashke content”) over the Internet on certain Internet-connected TVs, computers and other devices ("ashke ready devices").</p>
                        <p className='pb-3'>These Terms of Use govern your use of our service. As used in these Terms of Use, "ashke service", "our service" or "the service" means the personalized service provided by ashke for discovering and accessing ashke content, including all features and functionalities, recommendations and reviews, our websites, and user interfaces, as well as all content and software associated with our service. References to ‘you’ in these Terms of Use indicate the member who created the ashke account and whose payment method is charged.</p>
                        <h4 className='text-[22px] pb-2'>Membership</h4>
                        <p className='pb-3'>Your ashke membership will continue until terminated. To use the ashke service you must have Internet access and a ashke ready device, and provide us with one or more Payment Methods. “Payment Method” means a current, valid, accepted method of payment, as may be updated from time to time, and which may include payment through your account with a third party. Unless you cancel your membership before your billing date, you authorize us to charge the subscription fee for the next billing cycle to your Payment Method (see "Cancellation" below).</p>
                        <p className='pb-3'>We may offer a number of subscription plans, including subscriptions offered by third parties in conjunction with the provision of their own products and services. We are not responsible for the products and services provided by such third parties. Some subscription plans may have differing conditions and limitations, which will be disclosed at your sign-up or in other communications made available to you. You can find specific details regarding your ashke subscription by visiting the ashke.com website and clicking on the "Account" link available at the top of the pages under your profile name.</p>
                        <h3 className='text-[22px] pb-2'>Promotional Offers</h3>
                        <p className='pb-3'>We may from time to time offer special promotional offers, plans or memberships (“Offers”). Offer eligibility is determined by ashke at its sole discretion and we reserve the right to revoke an Offer and put your account on hold in the event that we determine you are not eligible. Members of households with an existing or recent ashke membership may not be eligible for certain introductory Offers. We may use information such as device ID, method of payment or an account email address used with an existing or recent ashke membership to determine Offer eligibility. The eligibility requirements and other limitations and conditions will be disclosed when you sign-up for the Offer or in other communications made available to you.</p>
                        <p><strong className='text-[19px] font-bold text-[#d42036] block mt-3'>🗸  Billing Cycle</strong>The subscription fee for the ashke service and any other charges you may incur in connection with your use of the service, such as taxes and possible transaction fees, will be charged to your Payment Method on the specific payment date indicated on the "Account" page. The length of your billing cycle will depend on the type of subscription plan that you choose when you signed up for the service. In some cases your payment date may change, for example if your Payment Method has not successfully settled, when you change your subscription plan or if your paid subscription began on a day not contained in a given month.</p>
                        <p><strong className='text-[19px] font-bold text-[#d42036] block mt-3'>🗸 Payment Methods </strong>To use the ashke service you must provide one or more Payment Methods. You authorize us to charge any Payment Method associated to your account in case your primary Payment Method is declined or no longer available to us for payment of your subscription fee. You remain responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your account, we may suspend your access to the service until we have successfully charged a valid Payment Method. For some Payment Methods, the issuer may charge you certain fees, such as foreign transaction fees or other fees relating to the processing of your Payment Method. Local tax charges may vary depending on the Payment Method used. Check with your Payment Method service provider for details.</p>
                        <p><strong className='text-[19px] font-bold text-[#d42036] block mt-3'>🗸 Updating your Payment Methods</strong>You can update your Payment Methods by going to the "Account" page. We may also update your Payment Methods using information provided by the payment service providers. Following any update, you authorize us to continue to charge the applicable Payment Method(s).</p>
                        <p><strong className='text-[19px] font-bold text-[#d42036] block mt-3'>🗸 Cancellation</strong>You can cancel your ashke membership at any time, and you will continue to have access to the ashke service through the end of your billing period. To the extent permitted by the applicable law, payments are non-refundable and we do not provide refunds or credits for any partial membership periods or unused ashke content. To cancel, go to the "Account" page and follow the instructions for cancellation. If you cancel your membership, your account will automatically close at the end of your current billing period. To see when your account will close, click "Billing details" on the "Account" page. If you signed up for ashke using your account with a third party as a Payment Method and wish to cancel your ashke membership, you may need to do so through such third party, for example by visiting your account with the applicable third party and turning off auto-renew, or unsubscribing from the ashke service through that third party.</p>
                        <p><strong className='text-[19px] font-bold text-[#d42036] block mt-3'>🗸 Changes to the Price and Subscription Plans</strong>We may change our subscription plans and the price of our service from time to time. We will notify you at least one month before any price changes or changes to your subscription plan will become effective.</p>
                        <p><strong className='text-[19px] font-bold text-[#d42036] block mt-3'>🗸 Changes to Terms of Use and Assignment </strong>ashke may, from time to time, change these Terms of Use. In case of material changes we will notify you at least one month before such changes apply to you. We may assign or transfer our agreement with you including our associated rights and obligations at any time and you agree to cooperate with us in connection with such an assignment or transfer.</p>
                    </div>
                </div>
            </section>
            <p></p>
        </div>
    )
}

export default TermsofUse
