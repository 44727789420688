import Logo from '../../assets/images/Logo.png';
import Img1 from '../../assets/images/app-store.png'
import Img2 from '../../assets/images/google-ply.png';
import FooterLogo from '../../assets/images/Logo.png';
import video from '../../assets/images/brahmastra.mp4'
import Brahmastralogo from '../../assets/images/brahmastra.webp';
import LatestShowImg from '../../assets/images/latest-show.jpg';
import LatestShow1 from '../../assets/images/latest-show1.jpg';
import LatestShow2 from '../../assets/images/latest-show2.jpg';
import LatestShow3 from '../../assets/images/latest-show3.jpg';
import Shows from '../../assets/images/shows-banner.jpg';
import Shows1 from '../../assets/images/shows-banner1.jpg';
import Shows2 from '../../assets/images/shows-banner2.jpg';
import Shows3 from '../../assets/images/shows-banner3.jpg';
import latestmovie from '../../assets/images/slider_banner.jpg';
import Add from '../../assets/images/add.avif';
import loaderImg from '../../assets/images/loader.gif';
import Slider1 from '../../assets/images/slider1.jpg';
import Slider2 from '../../assets/images/slider2.jpg';
import Slider3 from '../../assets/images/slider3.jpg';
import Slider4 from '../../assets/images/slider4.jpg';
const ImageIcons = {
 Logo,
 Add,
 Img1,
 Img2,
 Slider1,
 Slider2,
 Slider3,
 Slider4,
 FooterLogo,
 video,
 Brahmastralogo,
 LatestShowImg,
 LatestShow1,
 LatestShow2,
 LatestShow3,
 Shows,
 Shows1,
 Shows2,
 Shows3,
 latestmovie,
 loaderImg
};

export default ImageIcons;