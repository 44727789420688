import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { PostRequest } from '../utils/request';
import { validateEmail, validatePhone } from '../utils/formValidation';
import { useNavigate } from 'react-router-dom';
import { windowScroll } from '../utils/windowScroll';
// import Toastify from '../components/shared/toastify';
import Loader from '../utils/loader';
const ContactUs = () => {
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const [contactData, setContactData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: ""
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        fullName: false,
        email: false,
        phoneNumber: false,
        subject: false,
        message: false,
        success: ""

    })
    console.log(disabled);
    const fullNameHandler = (e) => {
        const inputValue = e.target.value;
        const alphaValue = inputValue.replace(/[^A-Za-z\s]/g, '');
        if (alphaValue.length <= 30) {
            setContactData(prevState => ({ ...prevState, fullName: alphaValue }));
            setError(prevState => ({ ...prevState, message: "" }));
        }
    };
    const handlePhoneInputChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, "");

        if (numericValue.length <= 10) {
            setContactData((prevState) => ({ ...prevState, phoneNumber: numericValue }));
            setError((prevState) => ({ ...prevState, phoneNumber: false, message: "" }));
        }
    };
    const handleEmailInputChange = (e) => {
        const inputValue = e.target.value;
        const val = inputValue.replace(" ", "");
        setContactData((prevState) => ({ ...prevState, email: val }));
        setError((prevState) => ({ ...prevState, email: false, message: "" }));
    };
    const handleSubjectInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 50) {
            setContactData((prevState) => ({ ...prevState, subject: inputValue }));
            setError((prevState) => ({ ...prevState, message: "" }));
        }
    };
    const handleMessageInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 500) {
            setContactData((prevState) => ({ ...prevState, message: inputValue }));
            setError((prevState) => ({ ...prevState, message: "" }));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        setError((prevState) => ({ ...prevState, message: "" }));
        if (
            !contactData.fullName ||
            !contactData.email ||
            !contactData.phoneNumber ||
            !contactData.subject ||
            !contactData.message
        ) {
            setError((prevState) => ({
                ...prevState,
                success: false,
                message: "Fields must not be empty!",
            }));
            setDisabled(false);
        } else if (!validateEmail(contactData.email)) {
            setError((prevState) => ({
                ...prevState,
                email: true,
                message: "Email is invalid!",
            }));
            setDisabled(false);
        } else if (!validatePhone(contactData.phoneNumber)) {
            setError((prevState) => ({
                ...prevState,
                phoneNumber: true,
                message: "Phone number is invalid!",
            }));
            setDisabled(false);
        } else {
            setLoading(true);
            PostRequest(`${process.env.REACT_APP_API_URL}/contacts/con`, {
                fullName: contactData.fullName,
                email: contactData.email,
                phoneNumber: contactData.phoneNumber,
                subject: contactData.subject,
                message: contactData.message,
            })
                .then((response) => {
                    setError((prevState) => ({ ...prevState, success: true }));
                    setLoading(false);
                    setContactData((prevState) => ({
                        ...prevState,
                        fullName: "",
                        email: "",
                        subject: "",
                        phoneNumber: "",
                        message: "",
                    }));
                })
                .catch((err) => {
                    setError((prevState) => ({
                        ...prevState,
                        message: err?.data
                            ? err.data.error
                            : "Something went wrong. Try again later!",
                    }));
                    setDisabled(false);
                });
        }
    }
    useEffect(() => {
        if (error.success) {
            const timer = setTimeout(() => {
                navigate('/thanks');
                windowScroll();
                setError((prevState) => ({ ...prevState, success: false }));
                setDisabled(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [error.success, navigate]);

    return (
        <section className=''>
            <div className="py-[50px] relative min-h-screen flex items-center justify-center bg-gray-100">
                <div className="max-w-3xl w-full bg-white shadow-lg rounded-md overflow-hidden grid grid-cols-2 gap-0 mobilecontact">
                    <div className="p-8 bg-black text-white relative">
                        <span className="absolute animate-bounce bg-gradient-to-b from-gray-700 to-black w-32 h-32 rounded-full top-20 right-10"></span>
                        <span className="absolute animate-bounce bg-gradient-to-b from-gray-700 to-black w-20 h-20 rounded-full top-0 right-28"></span>
                        <form onSubmit={handleSubmit} className="relative z-10">
                            <h3 className="text-2xl font-semibold mb-6">Contact us</h3>
                            {loading && (
                                <Loader />
                            )}
                            <div>
                                <p className='text-red-700 font-semibold text-[16px] pb-2'>{error.message}</p>
                                {/* {error.success && <Toastify message="Email sent Successfully" />} */}
                            </div>
                            <div className="mb-4 relative">
                                <input type="text" name="name" onChange={(e) => fullNameHandler(e)} value={contactData.fullName} placeholder="Username" className="bg-transparent border border-white w-full py-2 px-4 text-white placeholder-white focus:outline-none focus:border-[#d42036] rounded-md transition duration-300" />
                            </div>
                            <div className="mb-4 relative">
                                <input type="email" name="email" onChange={(e) => { handleEmailInputChange(e) }} value={contactData.email} placeholder="Email*" className="bg-transparent border border-white w-full py-2 px-4 text-white placeholder-white focus:outline-none focus:border-[#d42036] rounded-md transition duration-300" />
                            </div>
                            <div className="mb-4 relative">
                                <input type="tel" name="phoneNumber" onChange={(e) => { handlePhoneInputChange(e) }} value={contactData.phoneNumber} placeholder="Phone*" className="bg-transparent border border-white w-full py-2 px-4 text-white placeholder-white focus:outline-none focus:border-[#d42036] rounded-md transition duration-300" />
                            </div>
                            <div className="mb-4 relative">
                                <input type="tel" name="subject" onChange={(e) => { handleSubjectInputChange(e) }} value={contactData.subject} placeholder="Subject*" className="bg-transparent border border-white w-full py-2 px-4 text-white placeholder-white focus:outline-none focus:border-[#d42036] rounded-md transition duration-300" />
                            </div>
                            <div className="mb-6 relative">
                                <textarea name="message" onChange={(e) => { handleMessageInputChange(e) }} value={contactData.message} placeholder="Message" className="bg-transparent border border-white w-full py-2 px-4 text-white placeholder-white focus:outline-none focus:border-[#d42036] rounded-md transition duration-300 h-36 resize-none"></textarea>
                            </div>
                            <button type='submit' className="subcribebtn bg-[#d42036] hover:bg-[#fff] hover:text-black hover:border-[#d42036] rounded-md text-white inline-block w-[100%] py-2 px-4 text-lg font-semibold duration-300" onClick={windowScroll}>
                                Submit
                            </button>

                        </form>
                    </div>
                    <div className="p-8">
                        <h3 className="text-[#d42036] text-2xl font-semibold mb-3 capitalize">Let's get in touch</h3>
                        {/* <p className="text-gray-700 leading-relaxed mb-6">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe dolorum adipisci recusandae praesentium dicta!
                        </p> */}
                        <div className="mb-6">
                            <div className="flex items-center text-gray-600 mb-2">
                                <FaLocationDot className='text-[#d42036] text-[20px] mr-[6px]' />
                                <p>Hall 3, IT Wing, B-70, Phase 7, Industrial Area, Punjab</p>
                            </div>
                            <div className="flex items-center text-gray-600 mb-2">
                                <MdEmail className='text-[#d42036] text-[20px] mr-[6px]' />
                                <p>help@ashke.com</p>
                            </div>
                            <div className="flex items-center text-gray-600">
                                <FaPhoneAlt className='text-[#d42036] text-[20px] mr-[6px]' />
                                <p>123-456-789</p>
                            </div>
                        </div>
                        <div className="mb-6">
                            <p className="text-gray-700">Connect with us</p>
                            <div className="flex mt-2">
                                <Link to="#" className="w-10 h-10 bg-gradient-to-br from-gray-800 to-black flex items-center justify-center text-white rounded-md mr-2">
                                    <FaFacebookF />
                                </Link>
                                <Link to="#" className="w-10 h-10 bg-gradient-to-br from-gray-800 to-black flex items-center justify-center text-white rounded-md mr-2">
                                    <GrInstagram />
                                </Link>
                                <Link to="#" className="w-10 h-10 bg-gradient-to-br from-gray-800 to-black flex items-center justify-center text-white rounded-md mr-2">
                                    <BsTwitterX />
                                </Link>
                                <Link to="#" className="w-10 h-10 bg-gradient-to-br from-gray-800 to-black flex items-center justify-center text-white rounded-md">
                                    <FaLinkedinIn />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13719.388935099598!2d76.69900595!3d30.722694799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1719406812940!5m2!1sen!2sin" title='map' width="100%" height="450"></iframe>
            </div>
        </section>
    );
}

export default ContactUs;
