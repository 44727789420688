import React, { useState } from 'react';
import { GiQueenCrown } from 'react-icons/gi';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdClose, MdMenu } from 'react-icons/md';
import { IoSearchOutline } from "react-icons/io5";
import { ImageIcons } from '../components/imageComponent';
import { Link } from 'react-router-dom';
function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  function onMenuToggle() {
    setMenuOpen(!menuOpen);
  }
  return (
    <header className="sticky top-0 left-0 right-0 px-3 z-20 bg-black lg:py-3">
      {/* <div className="container">
        <nav className="flex justify-between">
          <div className="flex items-center"> */}
          <Link to="/"><img className="responsive-logo w-[90px] pt-2" src={ImageIcons.Logo} alt="logo" /></Link>
            {/* <div
              className={`navLinks duration-500 absolute md:static md:w-auto  md:h-auto h-[85vh] flex md:items-center gap-[1.9vw] top-[100%] ${menuOpen ? 'left-[0%]' : 'left-[-100%]'
                } px-5 py-5 md:whitespace-nowrap`}
            >
              {navigationData.map((item, index) => {
                return (
                  <ul key={index} className={`flex md:flex-row flex-col md:items-center md:gap-[2vw] gap-8 ${item.display}`}>
                    <li className={`capitalize text-[18px] text-white`}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  </ul>
                )
              })}

            </div>
          </div>
          <div className="flex items-center">
            {navLinks.map((item, index) => {
              return (
              <ul key={index} className="mobile_menu flex md:flex-row flex-col md:items-center md:gap-[2vw] gap-8 mx-3">
                <li className="capitalize text-[18px] text-white">
                  <Link className="flex items-center" to={item.href}>
                    <button className="mr-[8px] text-[20px]">{item.icon}</button>
                    {item.title}
                  </Link>
                </li>
              </ul>
              )
            })}
            {menuOpen ? (
              <MdClose
                name={menuOpen ? 'close' : 'menu'}
                onClick={onMenuToggle}
                className="closemenu text-[30px] cursor-pointer md:hidden"
              />
            ) : (
              <MdMenu
                name={menuOpen ? 'close' : 'menu'}
                onClick={onMenuToggle}
                className="closemenu text-[30px] cursor-pointer md:hidden"
              />
            )}
          </div>
        </nav>
      </div> */}
    </header>
  )
}

const navigationData = [
  {
    title: "About Us",
    href: "/",
    display:'block'
  },
  {
    title: "Shows",
    href: "/"
  },
  {
    title: "Movies",
    href: "/",
    display:'block'
  },
  {
    title: "Music Video",
    href: "/",
    display:'block'
  },
  {
    title: "Movies1",
    href: "/",
    display:'hidden'
  },
  {
    title: "Music Video1",
    href: "/",
    display:'hidden'
  }
]

const navLinks = [
  {
    icon: <IoSearchOutline className='text-[20px]'/>,
    title: "Search",
    href: "/search"
  },
  {
    icon: <GiQueenCrown className='text-[20px]'/>,
    title: "Subscribe",
    href: "/"
  },
  {
    icon: <FaRegUserCircle className='text-[20px]'/>,
    title: " Login",
    href: "/"
  }
]
export default Header;
