import React from 'react';
import Banner from '../components/home/banner';
import LatestReleases from '../components/home/latestReleases';
import TvShows from '../components/home/tvShows';
import LatestMovies from '../components/home/latestMovies';
import Watching from '../components/home/watching';
import Buy from '../components/home/buy';
import Homepage from '../components/home/homapage';
function Home() {
  return (
    <div>
      {/* <Banner/>
      <LatestReleases/> */}
      {/* <TvShows/> */}
      {/* <Buy/> */}
      {/* <LatestMovies/> */}
      {/* <Watching/> */}
      <Homepage/>
    </div>
  )
}

export default Home;
