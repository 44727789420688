import React from 'react';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ImageIcons } from '../imageComponent';
import { Link } from 'react-router-dom';
function TvShows() {
  const settings = {
    infinite: true,           
    slidesToShow: 5,          
    slidesToScroll: 1,       
    autoplay: true,          
    autoplaySpeed: 2000,     
    arrows: false,           
    responsive: [            
      {
        breakpoint: 768,      
        settings: {
          slidesToShow: 2,    
            
          centerMode: false, 
          slidesToScroll: 1  
        }
      },
      {
        breakpoint: 600,     
        settings: {
          slidesToShow: 2,    
          slidesToScroll: 2  
        }
      },
      {
        breakpoint: 480,     
        settings: {
          slidesToShow: 2,    
          slidesToScroll: 2   
        }
      }
    ]
  };
  
  let slider;

  return (
    <section className='Ashke-Originals py-[50px] px-5'>
      <div className="container">
        <div className='relative'>
          <div className="lg:flex pb-6">
            <div className="w-1/2 lg:text-left">
              <h2 className='text-[30px] font-semibold text-[#d42036]'>Ashke TV Shows</h2>
            </div>
            <div className="w-1/2 flex items-center justify-end">
              <button className='ViewAllbtn font-[400] text-[17px] cursor-pointer text-white'>
                View All <MdKeyboardDoubleArrowDown className='animate-bounce inline-flex text-[22px] text-[#d42036]' />
              </button>
            </div>
          </div>
          <Slider ref={c => (slider = c)} {...settings}>
            {sliderImages.map((item, index) => (
              <div key={index}>
                <ul className="flex relative">
                  <li className="w-full mx-[12px] cursor-pointer">
                    <Link to={item.href}>
                      <img src={item.image} alt="banner" className="rounded-lg w-full object-cover image-hover relative" />
                      <img className='width-[70px] absolute top-0 left-0 pt-[12px]' src='https://altt.co.in/assets/svgs/free.svg' alt='free logo' />
                    </Link>
                  </li>
                </ul>
              </div>
            ))}
          </Slider>
          <div>
            <GoChevronLeft
              onClick={() => slider.slickPrev()}
              className="responsive-icon absolute top-[50%] left-[-50px] text-[48px] cursor-pointer text-[#d42036] hover:text-white transition duration-400"
            />
            <GoChevronRight
              onClick={() => slider.slickNext()}
              className="responsive-icon absolute top-[50%] right-[-50px] text-[48px] cursor-pointer text-[#d42036] hover:text-white transition duration-400"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
const sliderImages = [
  {
    href: '/',
    image: ImageIcons.LatestShowImg,
  },
  {
    href: '/',
    image: ImageIcons.LatestShow1,
  },
  {
    href: '/',
    image: ImageIcons.LatestShow2,
  },
  {
    href: '/',
    image: ImageIcons.LatestShow3,
  },
  {
    href: '/',
    image: ImageIcons.LatestShowImg,
  },
  {
    href: '/',
    image: ImageIcons.LatestShow1,
  },
];
export default TvShows;
