import React from 'react';
import { ImageIcons } from '../imageComponent';
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { GoChevronRight } from "react-icons/go";
import { GoChevronLeft } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
function LatestReleases() {
  const settings = {
    infinite: true,           
    slidesToShow: 5,          
    slidesToScroll: 1,       
    autoplay: true,          
    autoplaySpeed: 2000,     
    arrows: false, 
    rtl: true,          
    responsive: [            
      {
        breakpoint: 768,      
        settings: {
          slidesToShow: 2,    
              
          centerMode: false, 
          slidesToScroll: 1  
        }
      },
      {
        breakpoint: 600,     
        settings: {
          slidesToShow: 2,    
          slidesToScroll: 2  
        }
      },
      {
        breakpoint: 480,     
        settings: {
          slidesToShow: 2,    
          slidesToScroll: 2   
        }
      }
    ]
  };

  let slider;

  return (
    <section className='Latest-shows-slider py-[50px] px-5'>
      <div className="container">
        <div className='relative'>
          <div className="lg:flex pb-6">
            <div className="w-1/2 lg:text-left">
              <h2 className='text-[30px] font-semibold text-[#d42036]'>Latest Releases</h2>
            </div>
            <div className="w-1/2 flex items-center justify-end">
              <button className='ViewAllbtn font-[400] text-[17px] cursor-pointer text-[#fff]'>
                View All <MdKeyboardDoubleArrowDown className='animate-bounce inline-flex text-[22px] text-[#d42036]' />
              </button>
            </div>
          </div>
          <Slider ref={c => (slider = c)} {...settings}>
            {sliderData.map((item, index) => (
              <div key={index} >
                <div className="w-full overflow-hidden rounded-full cursor-pointer position-relative">
                <Link to={item.href}>
                  <img src={item.image} alt="banner" className="w-full object-cover image-hover" />
                </Link>
              </div>
              </div>
            ))}
          </Slider>
          <div>
          <GoChevronLeft
            onClick={() => slider.slickPrev()}
            className="responsive-icon absolute top-[50%] left-[-50px] text-[48px] cursor-pointer text-[#d42036] hover:text-[#fff] transition duration-400"
          />
          <GoChevronRight
            onClick={() => slider.slickNext()}
            className="responsive-icon absolute top-[50%] right-[-50px] text-[48px] cursor-pointer text-[#d42036] hover:text-[#fff] transition duration-400"
          />
          </div>
        </div>
        </div>
    </section>
  );
}

const sliderData = [
  { href: "/", image: ImageIcons.Shows },
  { href: "/", image: ImageIcons.Shows1 },
  { href: "/", image: ImageIcons.Shows2 },
  { href: "/", image: ImageIcons.Shows3 },
  { href: "/", image: ImageIcons.Shows2 },
  { href: "/", image: ImageIcons.Shows3 },
];

export default LatestReleases;
